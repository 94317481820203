
const Home = {
    text: 'Home',
    link: '/inventories',
    icon: 'fa fa-home'
};

const Users = {
    text: 'Users',
    translate: 'menu.users',
    link: '/users',
    icon: 'fa fa-user'
};

const Inventories = {
    text: 'Inventories',
    translate: 'menu.inventories',
    link: '/inventories',
    icon: 'fa fa-list'
}

const headingMain = {
    text: 'Main Navigation',
    heading: true
};

export const menu = [
    //headingMain,
    //Home,
    Inventories,
    Users
];

export const simpleMenu = [  
    Inventories
];

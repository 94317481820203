import { Injectable } from '@angular/core';
import { S1HttpClientService } from '@app/s1';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDepartment } from '../models';

enum API {
  departments = '/departments'
}

@Injectable({
  providedIn: 'root'
})
export class DeparmentService {

  private path = "/department"

  constructor(private s1HttpClient: S1HttpClientService) { }

  getDepartments(): Observable<IDepartment[]> {

    return this.s1HttpClient.get(this.path + API.departments).pipe(
      map(response => response.data.results)
    )
  }

}

export interface IUser {
  idUser?: number,
  username?: string,
  name: string,
  surname: string,
  email?: string,
  password?: string,
  codeRole: UserRole,
  descRole?: string,
  enabled: boolean,
  codeShop?: string
}

export enum UserRole {
  SUPERADMIN = "SUPERADMIN",
  HEADQUARTER = "HEADQUARTER",
  SHOP = "SHOP"
}

export interface IDepartment {
  id: number,
  description: string
}

export interface IInventory {
  id: number,
  codneg: string,
  idDepartment: number,
  descDepartment: string,
  timestampCreate: Date,
  dateInventory: Date,
  plusCount: number,
  status: InventoryStatus
}

export interface IPlu {
  idPlu: number,
  descPlu: string,
  tareWeight: number,
  netWeight: number,
  quantity: number,
  multiplier: number,
  type: number,
  timestampCreate: Date
}

export enum InventoryStatus {
  'NOT_SENT',
  'SENT',
  'DELETED'
}

export interface IShop {
  code: number,
  description: string
}
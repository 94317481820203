import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { S1Modal, IS1Modal, S1ModalSizes } from '../s1-modal/s1-modal.component';
import { S1ButtonType } from '../s1-button/s1-button.component';
import { S1Table } from '../s1-table/s1-table.component';
import { S1StandardSearchService, IS1RicercaStandardParams, IS1RicercaStandardResult } from '../../services/s1-standard-search.service';
import { TranslateService } from '@ngx-translate/core';

export interface IS1StandardSearchModalConfig {
  title: string
  path: string
}

@Component({
  selector: 's1-standard-search-modal',
  templateUrl: './s1-standard-search-modal.component.html',
  styleUrls: ['./s1-standard-search-modal.component.scss']
})
export class S1StandardSearchModal implements OnInit, IS1Modal {

  @Output() elementSelected = new EventEmitter<IS1RicercaStandardResult>();

  @ViewChild('modal') modal: S1Modal;
  @ViewChild('table') table: S1Table;

  searchForm: FormGroup
  config: IS1StandardSearchModalConfig
  title: string

  s1ButtonType = S1ButtonType
  
  constructor(private formBuilder: FormBuilder, private standardSearchService: S1StandardSearchService, private translate: TranslateService) {

    this.searchForm = this.formBuilder.group({
      'codice': [null, null],
      'descrizione': [null, null],
    })

  }

  ngOnInit(): void {
  }

  open(config: IS1StandardSearchModalConfig) {
    this.config = config
    this.title = config ? this.translate.instant("s1.standardSearchModal.title") + " " + this.translate.instant(config.title) : "";
    this.searchForm.reset();
    this.search()
    this.modal.open(S1ModalSizes.LG)
  }

  close() {
    
  }

  search() {

    const params: IS1RicercaStandardParams = {
      codice: this.searchForm.controls.codice.value,
      descrizione: this.searchForm.controls.descrizione.value,
      page: this.table.actualPage(),
      pageSize: this.table.itemsPerPage()
    }

    this.standardSearchService.ricerca(this.config.path, params).subscribe(resultsPaginati => {

      this.table.updateData(resultsPaginati.results, resultsPaginati.paginationInfo)

    })

  }

  resultSelected(result: IS1RicercaStandardResult) {
    this.elementSelected.emit(result)
    this.modal.close()
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { DeparmentService } from '@app/shared/services/deparment.service';

@Component({
  selector: 'sms-input-department-select',
  templateUrl: './input-department-select.component.html',
  styleUrls: ['./input-department-select.component.scss']
})
export class InputDepartmentSelectComponent implements OnInit {

  @Input() control: FormControl;

  departmentsList: IS1InputSelectItem[];

  constructor(private departmentService: DeparmentService) { }

  ngOnInit(): void {
    this.getDepartments()
  }

  private getDepartments() {

    this.departmentService.getDepartments().subscribe(departments => {
      this.departmentsList = departments.map(department => { 
        return { code: department.id, label: department.description } 
      })
    })

  }

}

import { Injectable } from '@angular/core';
import { S1HttpClientService } from '@app/s1';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IShop } from '../models';

export interface IShopsPaginated {
  shops: IShop[],
  total: number
}

enum API {
  shops = '/shops'
}

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  private path = "/shop"

  constructor(private s1HttpClient: S1HttpClientService) { }

  getShops(): Observable<IShopsPaginated> {
    return this.s1HttpClient.get(this.path + API.shops, {}).pipe(
      map(response => { return { shops: response.data.results, total: response.data.total } })
    )
  }

}

import { Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';

import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { ActivateComponent } from './pages/activate/activate.component';
import { LockComponent } from './pages/lock/lock.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { UserRole } from '@app/shared/models';
import { RoleGuardService } from '@app/shared/services/role-guard.service';

export const routes: Routes = [

    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'inventories', pathMatch: 'full' },
            { path: 'home', redirectTo: 'inventories', pathMatch: 'full' },
            //{ path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
            { path: 'inventories', loadChildren: () => import('./inventories/inventories.module').then(m => m.InventoriesModule) },
            { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule), data: { roles: [UserRole.SUPERADMIN]}, canActivate: [RoleGuardService] },
        ]
    },

    // Not lazy-loaded routes
    { path: 'login', component: LoginComponent },
    { path: 'login/:id', component: LoginComponent },
    //{ path: 'register', component: RegisterComponent },
    { path: 'recover', component: RecoverComponent },
    { path: 'activate/:id', component: ActivateComponent },
    { path: 'lock', component: LockComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },

    // Not found
    { path: '**', redirectTo: 'home' }

];

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SettingsService } from '../../../core/settings/settings.service';

import { LoggingService } from '../../../../app/core/services/log.service';
import { AuthService } from "../../../../app/core/services/auth.service";
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import swal from 'sweetalert2';

@Component({
    selector: 'app-activate',
    templateUrl: './activate.component.html',
    styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit {

    @ViewChild('formActivate') activateForm: NgForm;

    // string for errorMessage service
    msgTranslation;
    errorTranslation;
    errorMessage;
    styleInput = 'password';
    styleIcon = '';

    public account = {
      firstLoginToken: "",
      password: "",
    };

    constructor(public settings: SettingsService, private activeRoute: ActivatedRoute, private logger: LoggingService, private router: Router, private authService: AuthService, private translate: TranslateService) {
      this.errorTranslation =  this.translate.get('error');
      this.msgTranslation =  this.translate.get('msg');
    }

    ngOnInit() {
      const sub = this.activeRoute.params.subscribe(params => {
        this.account.firstLoginToken = params['id'];
      });
    }

    clickType() {
      if (this.styleInput === 'password') {
        this.styleIcon = '-slash';
        return this.styleInput = 'text';
      } else {
        this.styleIcon = '';
        return this.styleInput = 'password';
      }
    }

    onSubmit(): void {
      swal.fire({
        title: this.msgTranslation.value.loading,
        onOpen: function () {
          swal.showLoading();
        }
      });
      this.account.password = this.activateForm.value.password;
      swal.fire({ title: 'Attivazione in corso...', onOpen: function () { swal.showLoading(); }});

      this.authService.activate(this.account).subscribe(
          (response) => {
            if (response.outcome.success === true) {
              swal.fire(this.msgTranslation.value.pwd_sent, this.msgTranslation.value.activate_sent_ok, "success");
              this.router.navigate(['/login']);
            } else {
              swal.fire(this.msgTranslation.value.error, this.settings.manageErrorMsg(response.outcome), "error");
            }
          },
          (error) => {
            this.logger.log("Error", error, 200);
            this.errorMessage = this.errorTranslation.value.generic_error + ". " + this.errorTranslation.value.try_again;
            swal.fire(this.msgTranslation.value.error, this.errorMessage, "error");
          }
        );
      }
  }
<div class="input-group mb-2" *ngIf="grouped">
  <div class="input-group-prepend" *ngIf="label">
      <span class="input-group-text">{{ label | translate }}</span>
  </div>
  <ng-container *ngTemplateOutlet="grouped? content : null"></ng-container>
  <ng-content></ng-content>
</div>

<ng-container *ngIf="!grouped">
  <label *ngIf="label">
    {{ label | translate }}
  </label>
  <ng-container *ngTemplateOutlet="!grouped? content : null"></ng-container>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>

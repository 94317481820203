import { Injectable } from '@angular/core';
import { UserRole } from '@app/shared/models';

import { menu, simpleMenu } from '../../routes/menu';

@Injectable()
export class MenuService {

    menuItems: Array<any>;

    constructor() {
        this.menuItems = [];
    }

    addMenu(items: Array<{
        text: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        alert?: string,
        submenu?: Array<any>
    }>) {
        items.forEach((item) => {
            this.menuItems.push(item);
        });
    }

    getMenu() {
        return this.menuItems;
    }

    private resetMenu() {
        this.menuItems = [];
    }

    loadMenu() {
    
        const role = localStorage.getItem("role");

        this.resetMenu();

        if (role == UserRole.SUPERADMIN) {
            this.addMenu(menu);
        } else {
            this.addMenu(simpleMenu);
        }

    }

}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 's1-input-switch',
  templateUrl: './s1-input-switch.component.html',
  styleUrls: ['./s1-input-switch.component.scss']
})
export class S1InputSwitch implements OnInit {

  @Input() control: FormControl;
  @Input() label: string = '';
  @Input() labelGrouped: boolean = true;
  @Input() readonly: boolean = false;

  constructor() { }

  ngOnInit(): void { }

}

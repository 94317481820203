import { Component, Input, OnInit } from '@angular/core';
import { InventoryStatus } from '@app/shared/models';

@Component({
  selector: 'sms-inventory-status-badge',
  templateUrl: './inventory-status-badge.component.html',
  styleUrls: ['./inventory-status-badge.component.scss']
})
export class InventoryStatusBadgeComponent implements OnInit {

  @Input() status: InventoryStatus;

  constructor() { }

  ngOnInit(): void { }

}

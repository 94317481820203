import { DecimalPipe, registerLocaleData } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import localeIT from '@angular/common/locales/it';

registerLocaleData(localeIT);

@Pipe({
  name: 'smsKg'
})
export class SmsKgPipe extends DecimalPipe implements PipeTransform {

  transform(grams: number, ...args: unknown[]): string {

    const kg = grams / 1000

    return super.transform(kg, '1.3-3', 'it') + " Kg";
  
  }

}
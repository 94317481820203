<div class="item user-block" *ngIf="userBlockIsVisible()">
    <!-- User picture-->
    <!-- <div class="user-block-picture">
        <div class="user-block-status">
            <img class="img-thumbnail rounded-circle" [src]="user.picture" alt="Avatar" />
        </div>
    </div> -->
    <!-- Name and Job-->
    <div class="user-block-info">
        <span class="user-block-name">{{ 'sidebar.WELCOME' | translate }}  {{ user.name }}</span>
        <span class="user-block-role">{{ user.role }}</span>
    </div>
</div>

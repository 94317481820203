import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

export interface IS1InputSelectItem {
  code: string | number;
  label: string;
}

@Component({
  selector: 's1-input-select',
  templateUrl: './s1-input-select.component.html',
  styleUrls: ['./s1-input-select.component.scss']
})
export class S1InputSelect implements OnInit {

  @Input() control: FormControl;
  @Input() label: string = '';
  @Input() labelGrouped: boolean = true;
  @Input() readonly: boolean = false;
  @Input() itemsList: IS1InputSelectItem[];

  constructor() { }

  ngOnInit(): void {

  }

  checkIsValid(): boolean {

    return this.control.valid || !this.control.touched;

  }

}

import { Component, Input, OnInit } from '@angular/core';

import { IPlu } from '@app/shared/models';

@Component({
  selector: 'sms-plu-type-badge',
  templateUrl: './plu-type-badge.component.html',
  styleUrls: ['./plu-type-badge.component.scss']
})
export class PluTypeBadgeComponent implements OnInit {

  @Input() plu: IPlu;

  constructor() { }

  ngOnInit(): void { }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { SettingsService } from '@app/core/settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router, private settingsService: SettingsService) { }
  
  canActivate(route: ActivatedRouteSnapshot): boolean {
    
    const userRole = localStorage.getItem('role');

    if (!this.auth.isAuthenticated()) {
      this.settingsService.sessionExpired();
      this.router.navigate(['login']);
      return false;
    } else if (route.data!= null && route.data.roles!=null && !route.data.roles.some(role => role === userRole) ) {
      this.router.navigate(['/home']);
      return false;
    }

    return true;

  }

}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { ShopService } from '@app/shared/services/shop.service';

@Component({
  selector: 'sms-input-shop-select',
  templateUrl: './input-shop-select.component.html',
  styleUrls: ['./input-shop-select.component.scss']
})
export class InputShopSelectComponent implements OnInit {

  @Input() control: FormControl;

  shopsList: IS1InputSelectItem[];

  constructor(private shopService: ShopService) { }

  ngOnInit(): void {
    this.getShops()
  }

  private getShops() {

    this.shopService.getShops().subscribe(shopsPaginated => {
      this.shopsList = shopsPaginated.shops.map(shop => { 
        return { code: shop.code, label: shop.code + " - " + shop.description } 
      })
    })

  }

}

import { Component, OnInit, Input, Output, TemplateRef, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { S1AutocompleteService, IS1AutocompleteResult } from '../../services/s1-autocomplete.service';
import { IS1StandardSearchModalConfig } from '../s1-standard-search-modal/s1-standard-search-modal.component';
import { IS1RicercaStandardResult } from '../../services/s1-standard-search.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/public_api';

export interface IS1InputAutocompleteConfig {
  pathAutocomplete: string,
  standardSearch?: IS1StandardSearchModalConfig
}

@Component({
  selector: 's1-input-autocomplete',
  templateUrl: './s1-input-autocomplete.component.html',
  styleUrls: ['./s1-input-autocomplete.component.scss']
})
export class S1InputAutocomplete implements OnInit {

  @Input() config: IS1InputAutocompleteConfig;
  @Input() control: FormControl;
  @Input() label: string = '';
  @Input() name: string = '';
  @Input() isValid: boolean = true;
  @Input() readonly: boolean = false;
  @Input() withButtonList: boolean = true;
  @Output() elementSelected = new EventEmitter<IS1AutocompleteResult>();

  dataSource: Observable<any>
  public template: TemplateRef<any>

  constructor(private autocompleteService: S1AutocompleteService) { }

  ngOnInit(): void {

    this.dataSource = Observable.create(observer => { 
      this.autocompleteService.autocomplete(this.config.pathAutocomplete, ""+this.control?.value).subscribe(results => {
        observer.next(results)
      })
    })

  }

  changeTypeaheadLoading(event) { }
  
  changeTypeaheadNoResults(event) { }
   
  typeaheadOnSelect(element: TypeaheadMatch) {

    this.elementSelected.emit(element.item)

  }

  elementSelectedOnStandardSearchModal(element: IS1RicercaStandardResult) {

    this.control.patchValue(element.codice)
    this.elementSelected.emit(element)

  }

}
